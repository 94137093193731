<template>
  <div class="container mx-auto max-w-screen-md">
    <!-- component -->
    <div class="md:mt-20 sm:mt-0">
      <div class="md:gap-6">
        <div class="md:col-span-1">
          <div class="md:mb-10 px-4 sm:px-0">
            <h3 class="text-xl md:text-3xl leading-6 text-gray-900 font-bold">
              {{ t('QUALITIA DNS') }}
            </h3>
            <p class="text-gray-500 text-sm">
              {{ t('The price shown includes tax.') }}
            </p>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="text-left m-auto shadow overflow-hidden sm:rounded-md">
            <div class="grid grid-cols-2 px-4 py-5 text-white bg-red-500">
              <div class="text-lg col-span-2 text-center w-full">
                <p className="text-normal mb-1 ">
                  {{ t('QUALITIA DNS Monthly Plan') }}
                </p>

                <p class="font-bold text-3xl my-4">{{ monthlyPrice }}</p>
                <p className="text-xs">
                  {{
                    t(
                      'Click on the "Apply" button and a confirmation screen will appear.'
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-center sm:px-6">
              <button
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500"
                @click="clickedMonthly"
              >
                {{ t('Apply') }}
              </button>
            </div>
          </div>
          <div class="text-left m-auto shadow overflow-hidden sm:rounded-md">
            <div class="grid grid-cols-2 px-4 py-5 text-white bg-green-500">
              <div class="text-lg col-span-2 text-center">
                <p className="text-normal mb-1 ">
                  {{ t('QUALITIA DNS Annual Plan') }}
                </p>
                <p class="font-bold text-3xl my-4">{{ yearPrice }}</p>
                <p className="text-xs">
                  {{
                    t(
                      'Click on the "Apply" button and a confirmation screen will appear.'
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-center sm:px-6">
              <button
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500"
                @click="clickedYear"
              >
                {{ t('Apply') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, onBeforeMount, ref } from 'vue'
import { get as getCatalogs } from '@/api/customer/order/catalog'
import { useTranslation } from '@/locales'
import { post as postOrder } from '@/api/customer/order'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const monthlyPrice = ref('')
    const yearPrice = ref('')
    const { n } = useTranslation()
    const { t } = useTranslation()
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params

    onBeforeMount(() =>
      getCatalogs().then((res) => {
        // TODO Need refactor
        const m = res.filter(
          (r) => r.serviceName === 'qt-dns' && r.paymentFrequency === 'monthly'
        )
        monthlyPrice.value = n(Number(m[0].totalWithTax), 'currency')

        const y = res.filter(
          (r) => r.serviceName === 'qt-dns' && r.paymentFrequency === 'year'
        )
        yearPrice.value = n(Number(y[0].totalWithTax), 'currency')
      })
    )

    const clickedMonthly = () =>
      postOrder('qt-dns', 'monthly', 1, true).then(({ sessionId }) => {
        router.push({
          path: `/${locale}/service/order/confirm`,
          query: {
            id: sessionId!,
            service: 'qt-dns',
          },
        })
      })

    const clickedYear = () =>
      postOrder('qt-dns', 'year', 1, true).then(({ sessionId }) => {
        router.push({
          path: `/${locale}/service/order/confirm`,
          query: {
            id: sessionId!,
            service: 'qt-dns',
          },
        })
      })

    return {
      t,
      monthlyPrice,
      yearPrice,
      clickedMonthly,
      clickedYear,
    }
  },
})
</script>

<style scoped lang="postcss">
.grid div {
  @apply mx-2;
}

input[type='radio'] {
  @apply mt-1 py-2 px-3 block border border-gray-300 shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.required {
  @apply ml-2 bg-red-500 font-medium text-gray-50 text-center inline-block px-2 text-sm;
}
</style>
