import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export type Catalog = {
  name: string
  serviceName: string
  totalWithTax: string
  paymentFrequency: string
}

export const get = () =>
  new Promise<Catalog[]>((resolve, reject) =>
    http
      .get('customer/order/catalog')
      .then(async (r: any) => {
        const res = await r.json()
        console.log(res)
        resolve(res)
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject(undefined)
      })
  )
