
import * as Sentry from '@sentry/browser'
import { defineComponent, onBeforeMount, ref } from 'vue'
import { get as getCatalogs } from '@/api/customer/order/catalog'
import { useTranslation } from '@/locales'
import { post as postOrder } from '@/api/customer/order'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const monthlyPrice = ref('')
    const yearPrice = ref('')
    const { n } = useTranslation()
    const { t } = useTranslation()
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params

    onBeforeMount(() =>
      getCatalogs().then((res) => {
        // TODO Need refactor
        const m = res.filter(
          (r) => r.serviceName === 'qt-dns' && r.paymentFrequency === 'monthly'
        )
        monthlyPrice.value = n(Number(m[0].totalWithTax), 'currency')

        const y = res.filter(
          (r) => r.serviceName === 'qt-dns' && r.paymentFrequency === 'year'
        )
        yearPrice.value = n(Number(y[0].totalWithTax), 'currency')
      })
    )

    const clickedMonthly = () =>
      postOrder('qt-dns', 'monthly', 1, true).then(({ sessionId }) => {
        router.push({
          path: `/${locale}/service/order/confirm`,
          query: {
            id: sessionId!,
            service: 'qt-dns',
          },
        })
      })

    const clickedYear = () =>
      postOrder('qt-dns', 'year', 1, true).then(({ sessionId }) => {
        router.push({
          path: `/${locale}/service/order/confirm`,
          query: {
            id: sessionId!,
            service: 'qt-dns',
          },
        })
      })

    return {
      t,
      monthlyPrice,
      yearPrice,
      clickedMonthly,
      clickedYear,
    }
  },
})
